<template>
  <div class="teams-setting">
    <div class="header">
      <div class="style-title-setting-global">
        {{ $i18n.locale === 'fr' ? 'Gestion des équipes' : 'Team Management' }}
      </div>
      <v-btn
        :disabled="initLoading"
        dark
        color="#5C2DD3"
        @click.stop="handleClickBtnAddEquipe"
      >
        <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon
        >{{ $i18n.locale === 'fr' ? 'Ajouter une équipe' : 'Add team' }}
      </v-btn>
    </div>
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div class="header-team mt-3" v-else>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-if="teams && teams.length"
            v-model="full_name"
            @input="filter"
            clearable
            :loading="getTeamsLoading"
            :prepend-inner-9icon="'mdi-magnify'"
            :label="$t('search')"
            outlined
            :placeholder="
              `${$t('search')} ${$i18n.locale === 'fr' ? 'une équipe' : 'team'}`
            "
            :persistent-placeholder="true"
            dense
            hide-details
            color="#5C2DD3"
          ></v-text-field
        ></v-col>
        <v-spacer></v-spacer>

        <v-col>
          <div
            v-if="getTeamsLoading || loading"
            class="color-crm font-sz-12 font-text"
          >
            {{ $t('loading') }}
          </div></v-col
        >
      </v-row>
      <v-list dense class="list-ensemble mt-3" v-if="teams && teams.length">
        <v-list-group
          color="#5C2DD3"
          class="list-ensemble-item"
          no-action
          v-for="(Equipe, index) in teams"
          :key="index"
          :class="{
            'border-top-solid': index == 0
          }"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                <div class="font-text font-sz-14 bold-700 color-black">
                  {{ Equipe.name }}
                </div>
                <div
                  class="font-text font-sz-12 color-crm"
                  v-if="Equipe.type == 'regie'"
                >
                  Régie
                </div>
                <div
                  class="font-text font-sz-12 color-crm"
                  v-if="Equipe.type == 'filiale'"
                >
                  Filiale
                </div>
                <div
                  class="font-text font-sz-12 color-crm"
                  v-if="Equipe.type == 'ge'"
                >
                  Ge
                </div>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                class="icon-action mr-2"
                small
                outlined
                color="#5C2DD3"
                :title="$t('update')"
                @click.prevent.stop="handleUpdateClick(Equipe)"
              >
                <font-awesome-icon class="img-h-19" icon="pencil-alt" />
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn
                class="icon-action"
                :title="$t('deleted')"
                small
                outlined
                color="#5C2DD3"
                @click.prevent.stop="handleDeleteClick(Equipe)"
              >
                <font-awesome-icon class="img-h-19" icon="trash-alt" />
              </v-btn>
            </v-list-item-action>
          </template>
          <v-card class="card-list border-top-solid">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <div>
                    <strong class="font-text font-sz-13">
                      {{
                        $i18n.locale === 'fr'
                          ? "Chef d'équipe :"
                          : 'Team leader :'
                      }}
                    </strong>
                    <div class="font-text font-sz-12 mt-1">
                      {{ Equipe.manager.data.first_name }}
                      {{ Equipe.manager.data.last_name }}
                    </div>
                  </div>
                </v-list-item-title>

                <div class="mt-2">
                  <strong class="font-text font-sz-13">
                    {{
                      $i18n.locale === 'fr'
                        ? "Membres de l'équipe :"
                        : 'Team members :'
                    }}
                  </strong>
                  <div
                    class="content-members"
                    v-if="
                      Equipe.members &&
                        Equipe.members.data &&
                        Equipe.members.data.length
                    "
                  >
                    <ul
                      class="list-member"
                      v-for="item in Equipe.members.data"
                      :key="item.id"
                      :value="item.id"
                    >
                      <li>
                        <div
                          :class="{
                            'icon-exclamation': item.pipe_tech_deleted === 1
                          }"
                          :title="
                            item.pipe_tech_deleted === 1
                              ? $i18n.locale === 'fr'
                                ? 'Technicien supprimé de pipedrive'
                                : 'Technician removed from pipedrive'
                              : ''
                          "
                        >
                          {{ item.first_name }} {{ item.last_name }}
                        </div>
                        <div>
                          <strong class="color-crm font-text font-sz-12">
                            ({{ item.type | typeFormat }})
                          </strong>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div v-else class="color-crm font-text font-sz-12 mt-2">
                    {{
                      $i18n.locale === 'fr'
                        ? "Aucun membre de l'équipe"
                        : 'No team members'
                    }}
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-list-group>
      </v-list>
      <div v-else class="div-aucun-list">
        <div class="titre">
          {{ $i18n.locale === 'fr' ? 'Aucune équipe' : 'No team' }}
        </div>
      </div>
    </div>
    <!-- Add Team Modal -->
    <v-dialog v-model="addTeamsModal" max-width="900" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale === 'fr' ? 'Ajouter une équipe' : 'Add team' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('addTeamsModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text mt-2">
          <v-form ref="addTeamsModal" class="mt-2">
            <v-row class="row">
              <v-col>
                <!-- NOM TEAM -->
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="teamToAdd.name"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field>
                <!-- TYPES -->
                <v-select
                  label="Type"
                  dense
                  :placeholder="$t('searchMsg', { msg: 'type' })"
                  v-model="teamToAdd.type"
                  @input="changeType($event)"
                  return-object
                  :no-data-text="$t('noDataOptionMsg', { option: 'type' })"
                  item-text="name"
                  item-value="id"
                  :items="teamsTypes"
                  outlined
                  :persistent-placeholder="true"
                  required
                  validate-on-blur
                  :rules="[v => !!v || 'Type ' + $t('msgOblg')]"
                  class="msg-error mt-2"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select>
                <!-- TEAMS TYPE FILIALE AND GE -->
                <v-select
                  v-if="type == 'filiale' || type == 'ge'"
                  :label="$t('team_manager')"
                  dense
                  return-object
                  :no-data-text="
                    $t('noDataOptionMsg', { option: $t('team_manager') })
                  "
                  v-model="teamToAdd.manager_id"
                  item-text="name"
                  item-value="id"
                  :items="adminsGlobal"
                  outlined
                  :persistent-placeholder="true"
                  required
                  validate-on-blur
                  :rules="[
                    v => !!v || $t('team_manager') + ' ' + $t('msgOblg')
                  ]"
                  class="msg-error mt-2"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select>
                <!-- LOGO FILIALE -->
                <v-file-input
                  v-if="type == 'filiale'"
                  label="Logo Filiale"
                  v-model="teamToAdd.image"
                  @change="previewImage"
                  accept="image/*"
                  :hint="
                    $i18n.locale === 'fr'
                      ? 'Taille image 286 x 99 pixels'
                      : 'Image size 286 x 99 pixels'
                  "
                  persistent-hint
                  outlined
                  dense
                  :persistent-placeholder="true"
                  prepend-inner-icon
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-file-input>
                <!-- TEAMS TYPE REGIE -->
                <div v-if="type == 'regie'">
                  <!-- CHEF D'EQUIPE TYPE REGIE -->
                  <v-select
                    v-model="teamToAdd.manager_id_regie"
                    :label="$t('team_manager')"
                    dense
                    :no-data-text="
                      $t('noDataOptionMsg', { option: $t('team_manager') })
                    "
                    item-text="name"
                    item-value="id"
                    :items="admins"
                    outlined
                    return-object
                    :persistent-placeholder="true"
                    required
                    validate-on-blur
                    :rules="[
                      v => !!v || $t('team_manager') + ' ' + $t('msgOblg')
                    ]"
                    class="msg-error mt-2"
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="#5C2DD3"
                    item-color="#5C2DD3"
                  ></v-select>
                  <!-- FORMULAIRE RAPIDE -->
                  <v-switch
                    class="input-checkbox switch-bottom"
                    :label="
                      $i18n.locale === 'fr' ? 'Formulaire Rapide' : 'Quick form'
                    "
                    color="#5C2DD3"
                    v-model="teamToAdd.see_form_fast"
                  >
                  </v-switch>
                  <!-- SOURCE -->
                  <v-select
                    v-model="teamToAdd.source_id"
                    @input="selectedSource($event)"
                    label="Source"
                    dense
                    return-object
                    :no-data-text="$t('noDataOptionMsg', { option: 'source' })"
                    item-text="name_internal"
                    item-value="id"
                    :placeholder="$t('searchMsg', { msg: 'source' })"
                    :items="computedRegies"
                    outlined
                    :persistent-placeholder="true"
                    required
                    validate-on-blur
                    :rules="[v => !!v || 'Source ' + $t('msgOblg')]"
                    class="msg-error mt-2"
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="#5C2DD3"
                    item-color="#5C2DD3"
                  ></v-select>
                  <!-- AFFECTER LEAD -->
                  <div>
                    {{
                      $i18n.locale === 'fr'
                        ? 'Affecter Lead selon :'
                        : 'Assign Lead according to :'
                    }}
                  </div>
                  <div class="check-switch flex center">
                    <div class="font-sz-16 mr-2">source</div>
                    <v-switch
                      class=""
                      v-model="teamToAdd.set_lead"
                      color="#5C2DD3"
                      :label="$t('department')"
                    >
                    </v-switch>
                  </div>
                  <!-- COMMERCIAL SEDENTAIRE -->
                  <v-select
                    v-if="!teamToAdd.set_lead"
                    :label="$t('commercial_sedentaire')"
                    v-model="teamToAdd.comm_sed_id"
                    disabled
                    dense
                    :no-data-text="
                      $t('noDataOptionMsg', {
                        option: $t('commercial_sedentaire')
                      })
                    "
                    item-text="full_name"
                    item-value="id"
                    :items="getProjectsUsersCommercialsSedentaires"
                    outlined
                    :persistent-placeholder="true"
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="#5C2DD3"
                    item-color="#5C2DD3"
                  ></v-select>
                  <!-- LISTE DES DES TYPES DES PROJETS -->
                  <div
                    class="mb-3 pb-3"
                    v-if="
                      teamToAdd &&
                        teamToAdd.projectTypes &&
                        teamToAdd.projectTypes.length
                    "
                  >
                    <div>
                      {{
                        $i18n.locale === 'fr'
                          ? 'Liste des types des projets'
                          : 'List of project types'
                      }}
                    </div>
                    <template v-for="(type, index) in computedProjectTypes">
                      <v-checkbox
                        class="input-checkbox msg-error mt-2 label-check"
                        :label="type.name"
                        :key="'type-check-' + type.id"
                        :value="type.id"
                        v-model="teamToAdd.projectTypes[index].dtype_id"
                        color="#5C2DD3"
                        hide-details
                      ></v-checkbox>
                      <!-- <span class="project-type-sub-tree"></span> -->
                      <v-select
                        v-if="
                          teamToAdd &&
                            teamToAdd.projectTypes &&
                            teamToAdd.projectTypes.length &&
                            teamToAdd.projectTypes[index] &&
                            teamToAdd.projectTypes[index].dtype_id
                        "
                        :key="'type-select1-' + type.id"
                        :label="$t('categoriesStage')"
                        class="stade-sous-stade-flex msg-error mt-2"
                        v-model="teamToAdd.projectTypes[index].cstage_id"
                        @input="handleStageChange($event, type, index)"
                        dense
                        :no-data-text="
                          $t('noDataOptionMsg', {
                            option: $t('categoriesStage')
                          })
                        "
                        item-text="name"
                        item-value="id"
                        return-object
                        :placeholder="
                          $t('searchMsg', { msg: $t('categoriesStage') })
                        "
                        :items="type.stages"
                        :loading="getPipelinesLoading"
                        outlined
                        :persistent-placeholder="true"
                        :menu-props="{ bottom: true, offsetY: true }"
                        color="#5C2DD3"
                        item-color="#5C2DD3"
                        required
                        validate-on-blur
                        :rules="[
                          v =>
                            !!v || $t('categoriesStage') + ' ' + $t('msgOblg')
                        ]"
                      ></v-select>
                      <!-- <span class="project-type-sub-tree child"></span> -->
                      <v-select
                        v-if="
                          teamToAdd &&
                            teamToAdd.projectTypes &&
                            teamToAdd.projectTypes.length &&
                            teamToAdd.projectTypes[index] &&
                            teamToAdd.projectTypes[index].dtype_id &&
                            teamToAdd.projectTypes[index].cstage_id &&
                            teamToAdd.projectTypes[index].subStages
                        "
                        :key="'type-select2-' + type.id"
                        :label="$t('stade')"
                        class="stade-sous-stade-flex msg-error mt-2"
                        v-model="teamToAdd.projectTypes[index].csubstage_id"
                        dense
                        :loading="getPipelinesLoading"
                        :no-data-text="
                          $t('noDataOptionMsg', {
                            option: $t('stade')
                          })
                        "
                        item-text="name"
                        item-value="id"
                        :placeholder="$t('searchMsg', { msg: $t('stade') })"
                        :items="teamToAdd.projectTypes[index].subStages"
                        outlined
                        return-object
                        :persistent-placeholder="true"
                        :menu-props="{ bottom: true, offsetY: true }"
                        color="#5C2DD3"
                        item-color="#5C2DD3"
                        required
                        validate-on-blur
                        :rules="[v => !!v || $t('stade') + ' ' + $t('msgOblg')]"
                      ></v-select>
                    </template>
                  </div>
                  <!-- LES 3 COMMISSIONS-->
                  <v-text-field
                    label="Commission 101"
                    dense
                    :persistent-placeholder="true"
                    outlined
                    type="number"
                    v-model="teamToAdd.coeff_101"
                    color="#5C2DD3"
                    required
                    item-color="#5C2DD3"
                    validate-on-blur
                    :rules="[v => !!v || 'Commission 101 ' + $t('msgOblg')]"
                  >
                  </v-text-field>
                  <v-text-field
                    label="Commission 102"
                    dense
                    :persistent-placeholder="true"
                    outlined
                    type="number"
                    v-model="teamToAdd.coeff_102"
                    color="#5C2DD3"
                    required
                    item-color="#5C2DD3"
                    validate-on-blur
                    :rules="[v => !!v || 'Commission 102 ' + $t('msgOblg')]"
                  >
                  </v-text-field>
                  <v-text-field
                    label="Commission 103"
                    dense
                    :persistent-placeholder="true"
                    outlined
                    type="number"
                    v-model="teamToAdd.coeff_103"
                    color="#5C2DD3"
                    required
                    item-color="#5C2DD3"
                    validate-on-blur
                    :rules="[v => !!v || 'Commission 103 ' + $t('msgOblg')]"
                  >
                  </v-text-field>
                </div>
              </v-col>
              <v-col v-if="type" class="border-left-solid">
                <div
                  :class="{
                    defilement: type != 'regie',
                    'defilement-regie': type == 'regie'
                  }"
                >
                  <div v-if="type == 'regie'">
                    <div>
                      {{
                        $i18n.locale === 'fr'
                          ? 'Liste admins secondaires'
                          : 'Secondary admins list'
                      }}
                    </div>
                    <div
                      v-if="
                        listsAdminsRegieComputed &&
                          listsAdminsRegieComputed.length
                      "
                    >
                      <v-checkbox
                        class="input-checkbox msg-error mt-2 label-check"
                        v-for="(item, index) in listsAdminsRegieComputed"
                        :key="index"
                        v-model="teamToAdd.selected[item.id]"
                        color="#5C2DD3"
                        hide-details
                      >
                        <template v-slot:label>
                          <div class="flex-center">
                            {{ item.first_name }} {{ item.last_name }}
                            <span class="color-crm font-sz-12 bold-600 ml-2">
                              ({{ item.type | typeFormat }})
                            </span>
                          </div>
                        </template>
                      </v-checkbox>
                    </div>
                    <div v-else class="aucunMembres">
                      {{ $i18n.locale === 'fr' ? 'Aucun Admin' : 'No Admin' }}
                    </div>
                    <v-divider class="mt-3 mb-3"></v-divider>
                    <div>
                      {{
                        $i18n.locale === 'fr'
                          ? "Membres de l'équipe"
                          : 'Team members'
                      }}
                    </div>
                    <div
                      v-if="membersRegieComputed && membersRegieComputed.length"
                    >
                      <v-checkbox
                        class="input-checkbox msg-error mt-2 label-check"
                        v-for="(item, index) in membersRegieComputed"
                        :key="index"
                        v-model="teamToAdd.selected[item.id]"
                        color="#5C2DD3"
                        hide-details
                      >
                        <template v-slot:label>
                          <div>{{ item.first_name }} {{ item.last_name }}</div>
                          <div class="color-crm font-sz-12 bold-600 ml-2">
                            ({{ item.type | typeFormat }})
                          </div>
                        </template>
                      </v-checkbox>
                    </div>
                    <div v-else class="aucunMembres">
                      {{
                        $i18n.locale === 'fr' ? 'Aucun Membres' : 'No members'
                      }}
                    </div>
                  </div>

                  <div v-if="type == 'filiale'">
                    <div>
                      {{
                        $i18n.locale === 'fr'
                          ? 'Liste admins secondaires'
                          : 'Secondary admins list'
                      }}
                    </div>
                    <div
                      v-if="listsAdminsComputed && listsAdminsComputed.length"
                    >
                      <v-checkbox
                        class="input-checkbox msg-error mt-2 label-check"
                        v-for="(item, index) in listsAdminsComputed"
                        :key="index"
                        v-model="teamToAdd.selectedTechnicians[item.id]"
                        color="#5C2DD3"
                        hide-details
                      >
                        <template v-slot:label>
                          <div>{{ item.first_name }} {{ item.last_name }}</div>
                          <div class="color-crm font-sz-12 bold-600 ml-2">
                            ({{ item.type | typeFormat }})
                          </div>
                        </template>
                        <template v-slot:message></template>
                      </v-checkbox>
                    </div>
                    <div v-else class="aucunMembres">
                      {{ $i18n.locale === 'fr' ? 'Aucun Admin' : 'No Admin' }}
                    </div>
                    <v-divider class="mt-3 mb-3"></v-divider>
                    <div>
                      {{
                        $i18n.locale === 'fr'
                          ? "Membres de l'équipe"
                          : 'Team members'
                      }}
                    </div>
                    <div v-if="membersComputed && membersComputed.length">
                      <v-checkbox
                        class="input-checkbox msg-error mt-2 label-check"
                        v-for="(item, index) in membersComputed"
                        :key="index"
                        v-model="teamToAdd.selectedTechnicians[item.id]"
                        color="#5C2DD3"
                        hide-details
                      >
                        <template v-slot:label>
                          <div
                            :class="{
                              'icon-exclamation': item.pipe_tech_deleted === 1
                            }"
                            :title="
                              item.pipe_tech_deleted === 1
                                ? $i18n.locale === 'fr'
                                  ? 'Technicien supprimé de pipedrive'
                                  : 'Technician removed from pipedrive'
                                : ''
                            "
                          >
                            {{ item.first_name }} {{ item.last_name }}
                          </div>
                          <div class="color-crm font-sz-12 bold-600 ml-2">
                            ({{ item.type | typeFormat }})
                          </div>
                        </template>
                      </v-checkbox>
                    </div>
                    <div v-else class="aucunMembres">
                      {{
                        $i18n.locale === 'fr' ? 'Aucun Membres' : 'No members'
                      }}
                    </div>
                  </div>

                  <div v-if="type == 'ge'">
                    <div>
                      {{
                        $i18n.locale === 'fr'
                          ? "Membres de l'équipe"
                          : 'Team members'
                      }}
                    </div>
                    <div v-if="membresGe && membresGe.length">
                      <v-checkbox
                        class="input-checkbox msg-error mt-2 label-check"
                        v-for="(item, index) in membresGe"
                        :key="index"
                        v-model="teamToAdd.selectedGe[index]"
                        color="#5C2DD3"
                        hide-details
                      >
                        <template v-slot:label>
                          <div>{{ item.first_name }} {{ item.last_name }}</div>
                          <div class="color-crm font-sz-12 bold-600 ml-2">
                            ({{ item.type | typeFormat }})
                          </div>
                        </template>
                      </v-checkbox>
                    </div>
                    <div v-else class="aucunMembres">
                      {{
                        $i18n.locale === 'fr' ? 'Aucun Membres' : 'No members'
                      }}
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="loading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="error" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleSubmit"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('addTeamsModal')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Add Team Modal -->
    <!-- Update Team Modal -->
    <v-dialog v-model="ModalUpdate" max-width="1300" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale === 'fr' ? 'Modifier une équipe' : 'Edit team' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('ModalUpdate')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text mt-2">
          <v-form ref="ModalUpdate" class="mt-2" v-if="selectTeam">
            <v-row class="row">
              <v-col>
                <!-- NOM TEAM -->
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="selectTeam.name"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field>
                <!-- TYPES -->
                <v-select
                  label="Type"
                  dense
                  :placeholder="$t('searchMsg', { msg: 'type' })"
                  v-model="selectTeam.type"
                  @input="changeType($event)"
                  return-object
                  :no-data-text="$t('noDataOptionMsg', { option: 'type' })"
                  item-text="name"
                  item-value="id"
                  :items="teamsTypes"
                  outlined
                  disabled
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select>
                <!-- TEAMS TYPE FILIALE AND GE -->
                <v-select
                  v-if="selectTeam.type == 'filiale' || selectTeam.type == 'ge'"
                  :label="$t('team_manager')"
                  dense
                  return-object
                  :no-data-text="
                    $t('noDataOptionMsg', { option: $t('team_manager') })
                  "
                  v-model="managerselected"
                  item-text="name"
                  item-value="id"
                  :items="adminsComputed"
                  outlined
                  :persistent-placeholder="true"
                  required
                  validate-on-blur
                  :rules="[
                    v => !!v || $t('team_manager') + ' ' + $t('msgOblg')
                  ]"
                  class="msg-error mt-2"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select>
                <!-- LOGO FILIALE -->
                <v-file-input
                  v-if="selectTeam.type == 'filiale'"
                  label="Logo Filiale"
                  v-model="selectTeam.imageDataUpdate"
                  @change="previewImageUpdate"
                  accept="image/*"
                  :hint="
                    $i18n.locale === 'fr'
                      ? 'Taille image 286 x 99 pixels'
                      : 'Image size 286 x 99 pixels'
                  "
                  persistent-hint
                  outlined
                  dense
                  :persistent-placeholder="true"
                  prepend-inner-icon
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-file-input>
                <div class="mt-3" v-if="selectTeam.image">
                  <div class="mb-2">Logo</div>
                  <img class="image-preview" :src="selectTeam.image" />
                </div>
                <!-- TEAMS TYPE REGIE -->
                <div v-if="selectTeam.type == 'regie'">
                  <!-- CHEF D'EQUIPE TYPE REGIE -->
                  <v-select
                    v-model="managerselectedRegie"
                    :label="$t('team_manager')"
                    dense
                    :no-data-text="
                      $t('noDataOptionMsg', { option: $t('team_manager') })
                    "
                    item-text="full_name"
                    item-value="id"
                    :items="computedadminUpdate"
                    outlined
                    return-object
                    :persistent-placeholder="true"
                    required
                    validate-on-blur
                    :rules="[
                      v => !!v || $t('team_manager') + ' ' + $t('msgOblg')
                    ]"
                    class="msg-error mt-2"
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="#5C2DD3"
                    item-color="#5C2DD3"
                  ></v-select>
                  <!-- FORMULAIRE RAPIDE -->
                  <v-switch
                    class="input-checkbox switch-bottom"
                    :label="
                      $i18n.locale === 'fr' ? 'Formulaire Rapide' : 'Quick form'
                    "
                    color="#5C2DD3"
                    v-model="selectTeam.see_form_fast"
                  >
                  </v-switch>
                  <!-- SOURCE -->
                  <v-select
                    v-model="selectTeam.source_id"
                    @input="selectedSourceUpdate($event)"
                    label="Source"
                    dense
                    return-object
                    :no-data-text="$t('noDataOptionMsg', { option: 'source' })"
                    item-text="name_internal"
                    item-value="id"
                    :placeholder="$t('searchMsg', { msg: 'source' })"
                    :items="regieUpdate"
                    outlined
                    :loading="getTeamsLoading"
                    :persistent-placeholder="true"
                    required
                    validate-on-blur
                    :rules="[v => !!v || 'Source ' + $t('msgOblg')]"
                    class="msg-error mt-2"
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="#5C2DD3"
                    item-color="#5C2DD3"
                  ></v-select>
                  <!-- AFFECTER LEAD -->
                  <div>
                    {{
                      $i18n.locale === 'fr'
                        ? 'Affecter Lead selon :'
                        : 'Assign Lead according to :'
                    }}
                  </div>
                  <div class="check-switch flex center">
                    <div class="font-sz-16 mr-2">source</div>
                    <v-switch
                      class=""
                      v-model="selectTeam.set_lead"
                      color="#5C2DD3"
                      :label="$t('department')"
                    >
                    </v-switch>
                  </div>
                  <!-- COMMERCIAL SEDENTAIRE -->
                  <v-select
                    v-if="!selectTeam.set_lead"
                    :label="$t('commercial_sedentaire')"
                    v-model="selectTeam.comm_sed_id"
                    disabled
                    dense
                    :no-data-text="
                      $t('noDataOptionMsg', {
                        option: $t('commercial_sedentaire')
                      })
                    "
                    item-text="full_name"
                    item-value="id"
                    :items="getProjectsUsersCommercialsSedentaires"
                    outlined
                    :persistent-placeholder="true"
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="#5C2DD3"
                    item-color="#5C2DD3"
                  ></v-select>
                  <!-- LISTE DES DES TYPES DES PROJETS -->
                  <div
                    class="mb-3 pb-3"
                    v-if="
                      selectTeamProjectTypes && selectTeamProjectTypes.length
                    "
                  >
                    <div>
                      {{
                        $i18n.locale === 'fr'
                          ? 'Liste des types des projets'
                          : 'List of project types'
                      }}
                    </div>
                    <template v-for="(type, index) in computedProjectTypes">
                      <v-checkbox
                        class="input-checkbox msg-error mt-2 label-check"
                        :label="type.name"
                        v-if="selectTeamProjectTypes[index]"
                        :key="'type-check-u-' + type.id"
                        :value="type.id"
                        v-model="selectTeamProjectTypes[index].dtype_id"
                        color="#5C2DD3"
                        hide-details
                      ></v-checkbox>
                      <!-- <span
                        class="project-type-sub-tree"
                     :key="'type-select1-' + type.id"
                      ></span> -->
                      <v-select
                        v-if="
                          selectTeamProjectTypes &&
                            selectTeamProjectTypes.length &&
                            selectTeamProjectTypes[index] &&
                            selectTeamProjectTypes[index].dtype_id
                        "
                        :key="'type-select1-' + type.id"
                        :label="$t('categoriesStage')"
                        class="stade-sous-stade-flex msg-error mt-2"
                        v-model="selectTeamProjectTypes[index].cstage_id"
                        @input="handleStageUpdateChange($event, type, index)"
                        dense
                        :no-data-text="
                          $t('noDataOptionMsg', {
                            option: $t('categoriesStage')
                          })
                        "
                        item-text="name"
                        item-value="id"
                        return-object
                        :placeholder="
                          $t('searchMsg', { msg: $t('categoriesStage') })
                        "
                        :items="type.stages"
                        outlined
                        :persistent-placeholder="true"
                        :menu-props="{ bottom: true, offsetY: true }"
                        color="#5C2DD3"
                        item-color="#5C2DD3"
                        required
                        validate-on-blur
                        :rules="[
                          v =>
                            !!v || $t('categoriesStage') + ' ' + $t('msgOblg')
                        ]"
                      ></v-select>
                      <!-- <span
                        class="project-type-sub-tree child"
                        :key="'type-select2-' + type.id"
                      ></span> -->
                      <v-select
                        v-if="
                          selectTeamProjectTypes &&
                            selectTeamProjectTypes.length &&
                            selectTeamProjectTypes[index] &&
                            selectTeamProjectTypes[index].dtype_id &&
                            selectTeamProjectTypes[index].cstage_id &&
                            selectTeamProjectTypes[index].subStages
                        "
                        :key="'type-select2-' + type.id"
                        :label="$t('stade')"
                        class="stade-sous-stade-flex msg-error mt-2"
                        v-model="selectTeamProjectTypes[index].csubstage_id"
                        dense
                        :no-data-text="
                          $t('noDataOptionMsg', {
                            option: $t('stade')
                          })
                        "
                        item-text="name"
                        item-value="id"
                        :placeholder="$t('searchMsg', { msg: $t('stade') })"
                        :items="selectTeamProjectTypes[index].subStages"
                        outlined
                        return-object
                        :persistent-placeholder="true"
                        :menu-props="{ bottom: true, offsetY: true }"
                        color="#5C2DD3"
                        item-color="#5C2DD3"
                        required
                        validate-on-blur
                        :rules="[v => !!v || $t('stade') + ' ' + $t('msgOblg')]"
                      ></v-select>
                    </template>
                  </div>
                  <!-- LES 3 COMMISSIONS-->
                  <v-text-field
                    label="Commission 101"
                    dense
                    :persistent-placeholder="true"
                    outlined
                    v-model="selectTeam.coeff_101"
                    color="#5C2DD3"
                    required
                    item-color="#5C2DD3"
                    validate-on-blur
                    :rules="[v => !!v || 'Commission 101 ' + $t('msgOblg')]"
                  >
                  </v-text-field>
                  <v-text-field
                    label="Commission 102"
                    dense
                    :persistent-placeholder="true"
                    outlined
                    v-model="selectTeam.coeff_102"
                    color="#5C2DD3"
                    required
                    item-color="#5C2DD3"
                    validate-on-blur
                    :rules="[v => !!v || 'Commission 102 ' + $t('msgOblg')]"
                  >
                  </v-text-field>
                  <v-text-field
                    label="Commission 103"
                    dense
                    :persistent-placeholder="true"
                    outlined
                    v-model="selectTeam.coeff_103"
                    color="#5C2DD3"
                    required
                    item-color="#5C2DD3"
                    validate-on-blur
                    :rules="[v => !!v || 'Commission 103 ' + $t('msgOblg')]"
                  >
                  </v-text-field>
                </div>
              </v-col>
              <v-col class="border-left-solid">
                <div
                  :class="{
                    defilement: selectTeam.type != 'regie',
                    'defilement-regie': selectTeam.type == 'regie'
                  }"
                >
                  <div v-if="loading" class="color-crm font-text font-sz-12">
                    {{ $t('loading') }}
                  </div>
                  <div
                    v-if="
                      (selectTeam.type == 'filiale' ||
                        selectTeam.type == 'regie') &&
                        !loading
                    "
                  >
                    <div>
                      {{
                        $i18n.locale === 'fr'
                          ? 'Liste admins secondaires'
                          : 'Secondary admins list'
                      }}
                    </div>
                    <div
                      v-if="
                        selectTeam &&
                          listsAdminsUpdateComputed &&
                          listsAdminsUpdateComputed.length &&
                          !loading
                      "
                    >
                      <v-checkbox
                        class="input-checkbox msg-error mt-2 label-check"
                        v-for="(item, index) in listsAdminsUpdateComputed"
                        :key="index"
                        v-model="selectTeam.selected[item.id]"
                        color="#5C2DD3"
                        hide-details
                      >
                        <template v-slot:label>
                          <div>{{ item.first_name }} {{ item.last_name }}</div>
                          <div class="color-crm font-sz-12 bold-600 ml-2">
                            ({{ item.type | typeFormat }})
                          </div>
                        </template>
                        <template v-slot:message></template>
                      </v-checkbox>
                    </div>
                    <div v-else class="aucunMembres">
                      {{ $i18n.locale === 'fr' ? 'Aucun Admin' : 'No Admin' }}
                    </div>
                    <v-divider class="mt-3 mb-3"></v-divider>
                    <div>
                      {{
                        $i18n.locale === 'fr'
                          ? "Membres de l'équipe"
                          : 'Team members'
                      }}
                    </div>
                    <div
                      v-if="
                        selectTeam &&
                          membersComputedUpdate &&
                          membersComputedUpdate.length &&
                          !loading
                      "
                    >
                      <v-checkbox
                        class="input-checkbox msg-error mt-2 label-check"
                        v-for="(item, index) in membersComputedUpdate"
                        :key="index"
                        v-model="selectTeam.selected[item.id]"
                        color="#5C2DD3"
                        hide-details
                      >
                        <template v-slot:label>
                          <div
                            :class="{
                              'icon-exclamation': item.pipe_tech_deleted === 1
                            }"
                            :title="
                              item.pipe_tech_deleted === 1
                                ? $i18n.locale === 'fr'
                                  ? 'Technicien supprimé de pipedrive'
                                  : 'Technician removed from pipedrive'
                                : ''
                            "
                          >
                            {{ item.first_name }} {{ item.last_name }}
                          </div>
                          <div class="color-crm font-sz-11 bold-600 ml-2">
                            ({{ item.type | typeFormat }})
                          </div>
                        </template>
                      </v-checkbox>
                    </div>
                    <div v-else class="aucunMembres">
                      {{
                        $i18n.locale === 'fr' ? 'Aucun Membres' : 'No members'
                      }}
                    </div>
                  </div>

                  <div v-if="selectTeam.type == 'ge' && !loading">
                    <div>
                      {{
                        $i18n.locale === 'fr'
                          ? "Membres de l'équipe"
                          : 'Team members'
                      }}
                    </div>
                    <div
                      v-if="
                        selectTeam &&
                          membresPossibleUpdate &&
                          membresPossibleUpdate.length
                      "
                    >
                      <v-checkbox
                        class="input-checkbox msg-error mt-2 label-check"
                        v-for="(item, index) in membresPossibleUpdate"
                        :key="index"
                        v-model="selectTeam.selected[item.id]"
                        color="#5C2DD3"
                        hide-details
                      >
                        <template v-slot:label>
                          <div
                            :class="{
                              'icon-exclamation': item.pipe_tech_deleted === 1
                            }"
                            :title="
                              item.pipe_tech_deleted === 1
                                ? $i18n.locale === 'fr'
                                  ? 'Technicien supprimé de pipedrive'
                                  : 'Technician removed from pipedrive'
                                : ''
                            "
                          >
                            {{ item.first_name }} {{ item.last_name }}
                          </div>
                          <div class="color-crm font-sz-11 bold-600 ml-2">
                            ({{ item.type | typeFormat }})
                          </div>
                        </template>
                      </v-checkbox>
                    </div>
                    <div v-else class="aucunMembres">
                      {{
                        $i18n.locale === 'fr' ? 'Aucun Membres' : 'No members'
                      }}
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div
              v-if="loading || loadingUpdate"
              class="loading font-text font-sz-12"
            >
              {{ $t('loading') }}
            </div>
            <div v-if="error" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleSubmitUpdate"
            :loading="loadingUpdate"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('ModalUpdate')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Update Team Modal -->

    <!-- Delete Team Modal -->
    <v-dialog v-model="ModalDelete" max-width="500" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale === 'fr' ? 'Supprimer une équipe' : 'Delete team' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('ModalDelete')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div
            v-if="
              equipeToDelete &&
                equipeToDelete.type == 'filiale' &&
                equipeToDelete.depots &&
                equipeToDelete.depots.data &&
                equipeToDelete.depots.data.length
            "
          >
            <p class="font-text">
              {{
                $t('msgdeleted', {
                  msg: $i18n.locale === 'fr' ? 'la filiale' : 'filiale'
                })
              }}
              <strong> {{ equipeToDelete ? equipeToDelete.name : '' }}</strong>
              ?
            </p>
            <p>
              <span class="color-red font-text bold-500 font-sz-12">
                Attention :</span
              >
              {{
                $i18n.locale === 'fr'
                  ? 'sa suppression va engendrer la suppression de ses dépôts :'
                  : 'its removal will lead to the removal of its deposits :'
              }}
              <strong>
                {{
                  equipeToDelete ? equipeToDelete.depots.data[0].name : ''
                }}</strong
              >
            </p>
          </div>
          <div v-else>
            <p class="font-text">
              {{
                $t('msgdeleted', {
                  msg: $i18n.locale === 'fr' ? "l'équipe" : 'the team'
                })
              }}
              <strong> {{ equipeToDelete ? equipeToDelete.name : '' }}</strong>
              ?
            </p>
          </div>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="loading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="error" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleModalValider"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('ModalDelete')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Delete Team Modal -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Teams',
  components: {},
  data() {
    return {
      ModalDelete: false,
      teamToAdd: {
        type: null,
        name: null,
        source_id: null,
        see_form_fast: 0,
        set_lead: 0,
        // pipe_user_id: null,
        coeff_101: null,
        coeff_102: null,
        coeff_103: null,
        manager_id: null,
        manager_id_regie: null,
        comm_sed_id: null,
        projectTypes: [],
        members: [],
        selected: [],
        selectedTechnicians: [],
        selectedGe: [],
        image: null,
        imageData: '',
        imageDataUpdate: ''
      },
      type: null,
      selectSource: null,
      selectTeam: null,
      selectTeamProjectTypes: [],
      selectedcheck: null,
      equipeToDelete: null,
      loading: false,
      error: null,
      initLoading: true,
      membresPossibleUpdate: [],
      managerselected: [],
      managerselectedRegie: [],
      full_name: null,
      addTeamsModal: false,
      ModalUpdate: false,
      loadingUpdate: false
    }
  },
  methods: {
    ...mapActions([
      'updateTeams',
      'getAdminsTeams',
      'getAdminsTeamsGlobal',
      'deleteTeams',
      'createTeam',
      'getTeams',
      'getStages',
      'getSubStages',
      'getPipeline',
      'getRegies',
      'getOwners',
      'getcommercials',
      'gettechnicians',
      'getteamsTypes',
      'getMembresPossibleUpdate',
      'getMembresGe',
      'fetchAdminRegie',
      'fetchRegieUpdate',
      'fetchProjetsTypes',
      'fetchPipelinesPipelines',
      'fetchUsersCommercialSedentaire'
    ]),

    handleClickBtnAddEquipe() {
      this.addTeamsModal = true
      if (this.getProjectsTypes && this.getProjectsTypes.length) {
        for (let index = 0; index < this.getProjectsTypes.length; index++) {
          this.teamToAdd.projectTypes.push({
            dtype_id: null,
            cstage_id: null,
            csubstage_id: null,
            subStages: null
          })
        }
      }
    },
    previewImage: function(event) {
      var input = event
      if (input) {
        var reader = new FileReader()
        reader.onload = () => {
          this.teamToAdd.imageData = input
        }
        reader.readAsDataURL(input)
      }
    },
    previewImageUpdate: function(event) {
      var input = event
      if (input) {
        var reader = new FileReader()
        reader.onload = () => {
          this.selectTeam.imageDataUpdate = input
        }
        reader.readAsDataURL(input)
      }
    },
    closeDialog(ref) {
      this[ref] = false
      if (ref == 'addTeamsModal') {
        this.$refs.addTeamsModal.resetValidation()
      }
      if (ref == 'ModalUpdate') {
        this.$refs.ModalUpdate.resetValidation()
      }

      this.resetModal()
    },
    async handleModalValider() {
      this.loading = true
      this.error = null
      const response = await this.deleteTeams(this.equipeToDelete.id)
      if (response.success) {
        this.loading = false
        this.getRegies()
        this.getAdminsTeamsGlobal({
          exclude: [
            'team',
            'visibleTeams',
            'technicianPipe',
            'commercialItePipe',
            'userPipe'
          ]
        })
        this.getAdminsTeams()
        this.gettechnicians()
        this.getcommercials()
        this.getMembresGe()
        this.closeDialog('ModalDelete')
      } else {
        this.loading = false
        this.error = response.error
      }
    },
    changeType(type) {
      this.type = type && type.id ? type.id : type
    },
    handleDeleteClick(Equipe) {
      this.equipeToDelete = Equipe
      this.ModalDelete = true
    },
    handleStageChange(value, type, index) {
      if (value && type && index != null) {
        this.teamToAdd.projectTypes[index].subStages = null
        this.teamToAdd.projectTypes[index].csubstage_id = null
        for (let i = 0; i < type.stages.length; i++) {
          const element = type.stages[i]
          if (value.id == element.id) {
            this.teamToAdd.projectTypes[index].subStages = element.subStages
          }
        }
      }
    },
    async handleUpdateClick(team) {
      if (team.pipeline) {
        this.selectTeam = { ...team }
      } else {
        this.selectTeam = {
          ...team,
          pipeline: { data: { id: null } },
          stage: { data: { id: null } },
          substage: { data: { id: null } }
        }
      }

      this.ModalUpdate = true
      this.loading = true

      if (this.selectTeam.type == 'regie') {
        // this.selectTeam.pipe_user_id = this.selectTeam.proprietaire.data.id
        this.selectTeamProjectTypes = []
        let visibleProjectTypes = []
        if (
          this.selectTeam.visibleDtypes &&
          this.selectTeam.visibleDtypes.data
        ) {
          visibleProjectTypes = this.selectTeam.visibleDtypes.data
        }

        for (let index = 0; index < this.computedProjectTypes.length; index++) {
          const visiblePType = visibleProjectTypes.find(
            item => item.dtype_id == this.computedProjectTypes[index].id
          )
          const ptype = {
            dtype_id: null,
            cstage_id: null,
            csubstage_id: null,
            subStages: null
          }
          if (visiblePType) {
            ptype.dtype_id = visiblePType.dtype_id
            ptype.cstage_id = {
              id: visiblePType.cstage_id,
              name: visiblePType.cstage_name
            }
            ptype.csubstage_id = {
              id: visiblePType.csubstage_id,
              name: visiblePType.csubstage_name
            }
            const selectedStage = this.computedProjectTypes[index].stages.find(
              item => item.id == ptype.cstage_id.id
            )
            if (selectedStage) {
              ptype.subStages = selectedStage.subStages
            }
          }
          this.selectTeamProjectTypes.push(ptype)
        }
        this.fetchAdminRegie(this.selectTeam.id)
        this.managerselectedRegie = this.selectTeam.manager.data
        await this.fetchRegieUpdate(this.selectTeam.id)

        for (let index = 0; index < this.regieUpdate.length; index++) {
          const source = this.regieUpdate[index]
          if (this.selectTeam && this.selectTeam.source_id == source.id) {
            this.selectSource = source.internal
            if (this.selectSource == 0) {
              this.selectTeam.comm_sed_id = source.commercial_sedentaire.data.id
            }
            this.selectTeam.source_id = {
              name_internal: source.name_internal,
              id: source.id
            }
          }
        }
      }
      // Team Members
      if (this.selectTeam) {
        this.selectTeam.selected = []
        this.selectTeam.members.data.forEach(member => {
          if (member.type != 'user.final') {
            this.selectTeam.selected[member.id] = true
          }
        })
      }

      if (
        this.selectTeam &&
        (this.selectTeam.type == 'filiale' || this.selectTeam.type == 'ge')
      ) {
        this.getAdminsTeamsGlobal({
          exclude: [
            'team',
            'visibleTeams',
            'technicianPipe',
            'commercialItePipe',
            'userPipe'
          ]
        })
        this.managerselected = this.selectTeam.manager.data
      }
      this.membresPossibleUpdate = []
      if (this.selectTeam) {
        this.membresPossibleUpdate = await this.getMembresPossibleUpdate({
          id: this.selectTeam.id,
          per_page: 50,
          exclude: [
            'team',
            'visibleTeams',
            'technicianPipe',
            'commercialItePipe',
            'userPipe'
          ]
        })
      }
      this.loading = false
    },
    resetModal() {
      this.teamToAdd = {
        type: '',
        name: null,
        source_id: null,
        see_form_fast: 0,
        set_lead: 0,
        // pipe_user_id: null,
        coeff_101: null,
        coeff_102: null,
        coeff_103: null,
        manager_id: null,
        manager_id_regie: null,
        projectTypes: [],
        members: [],
        selected: [],
        selectedTechnicians: [],
        selectedGe: [],
        image: null,
        imageData: '',
        imageDataUpdate: '',
        comm_sed_id: null
      }
      this.type = null
      this.selectSource = null
      this.selectTeam = null
      this.selectTeamProjectTypes = []
      this.selectedcheck = null
      this.equipeToDelete = null
      this.loading = false
      this.error = null
      this.membresPossibleUpdate = []
      this.managerselected = []
      this.managerselectedRegie = []
      for (let index = 0; index < this.getProjectsTypes.length; index++) {
        this.teamToAdd.projectTypes.push({
          dtype_id: null,
          cstage_id: null,
          csubstage_id: null,
          subStages: null
        })
      }
    },
    handleStageUpdateChange(value, type, index) {
      if (value && type && index != null) {
        this.selectTeamProjectTypes[index].subStages = null
        this.selectTeamProjectTypes[index].csubstage_id = null
        if (type && type.stages && type.stages.length) {
          for (let i = 0; i < type.stages.length; i++) {
            const element = type.stages[i]
            if (value.id == element.id) {
              this.selectTeamProjectTypes[index].subStages = element.subStages
            }
          }
        }
      }
    },
    validateFilter(teams, update) {
      let type = null
      if (teams.type.id) {
        type = teams.type.id
      } else {
        type = teams.type
      }
      const validate = {
        status: 'success',
        errors: []
      }
      if (type == 'regie') {
        const visibleProjectTypes = teams.projectTypes.filter(item => {
          if (item.dtype_id) {
            return true
          }
          return false
        })
        if (visibleProjectTypes && visibleProjectTypes.length == 0) {
          validate.status = 'error'
          validate.errors.push(
            this.$i18n.locale === 'fr'
              ? 'Il faut choisir un type de projet'
              : 'You have to choose a type of project'
          )
        } else {
          for (let index = 0; index < visibleProjectTypes.length; index++) {
            const type = visibleProjectTypes[index]
            if (
              type.dtype_id != 112 &&
              (!type.cstage_id || !type.csubstage_id)
            ) {
              validate.status = 'error'
              validate.errors.push(
                this.$i18n.locale === 'fr'
                  ? 'les champs des types des projets sont obligatoires'
                  : 'project type fields are required'
              )
            }
          }
        }

        if (update) {
          if (!teams.selectManagerRegie) {
            validate.status = 'error'
            validate.errors.push(
              this.$i18n.locale === 'fr'
                ? "Le champ Chef d'équipe est obligatoire"
                : 'Team Leader is required'
            )
          }
        } else {
          if (!teams.manager_id_regie) {
            validate.status = 'error'
            validate.errors.push(
              this.$i18n.locale === 'fr'
                ? "Le champ Chef d'équipe est obligatoire"
                : 'Team Leader is required'
            )
          }
        }
        if (!teams.source_id) {
          validate.status = 'error'
          validate.errors.push(
            this.$i18n.locale === 'fr'
              ? 'Le champ source est obligatoire'
              : 'Source is required'
          )
        }
      } else {
        if (update) {
          if (!teams.selectManager) {
            validate.status = 'error'
            validate.errors.push(
              this.$i18n.locale === 'fr'
                ? "Le champ Chef d'équipe est obligatoire"
                : 'Team Leader is required'
            )
          }
        } else {
          if (!teams.manager_id) {
            validate.status = 'error'
            validate.errors.push(
              this.$i18n.locale === 'fr'
                ? "Le champ Chef d'équipe est obligatoire"
                : 'Team Leader is required'
            )
          }
        }
      }
      return validate
    },
    async handleSubmit() {
      if (this.$refs.addTeamsModal.validate()) {
        const validate = this.validateFilter(this.teamToAdd)
        if (validate.status === 'error') {
          this.error = validate.errors
          return
        }
        if (this.type == 'regie') {
          const members = []
          this.teamToAdd.selected.forEach((element, key) => {
            if (element) {
              members.push(key)
            }
          })
          this.teamToAdd.members = members
        } else if (this.type == 'filiale') {
          // Team members (filiale)
          const members = []
          this.teamToAdd.selectedTechnicians.forEach((element, key) => {
            if (element) {
              members.push(key)
            }
          })
          this.teamToAdd.members = members
        } else if (this.type == 'ge') {
          // Team members (ge)
          const members = []
          for (
            let index = 0;
            index < this.teamToAdd.selectedGe.length;
            index++
          ) {
            if (this.teamToAdd.selectedGe[index]) {
              members.push(this.membresGe[index].id)
            }
          }
          this.teamToAdd.members = members
        }
        /**
         * formatting request
         */
        const formData = new FormData()
        // Team type
        formData.append('type', this.type)
        // Team name
        formData.append('name', this.teamToAdd.name)
        // Team manager (filiale/ge)
        if (this.type == 'filiale' || this.type == 'ge') {
          formData.append('manager_id', this.teamToAdd.manager_id.id)
        }
        // Team members
        if (this.teamToAdd.members) {
          for (let index = 0; index < this.teamToAdd.members.length; index++) {
            formData.append(
              'members[' + index + ']',
              this.teamToAdd.members[index]
            )
          }
        }

        // Team logo (filiale)
        if (this.type == 'filiale') {
          if (this.teamToAdd.imageData) {
            formData.append('image', this.teamToAdd.imageData)
          }
        }

        if (this.type == 'regie') {
          // Team manager (regie)
          formData.append('manager_id', this.teamToAdd.manager_id_regie.id)
          // Team source (regie)
          formData.append('source_id', this.teamToAdd.source_id.id)
          // Team form (regie)
          formData.append('see_form_fast', this.teamToAdd.see_form_fast ? 1 : 0)
          //Team affecter lead
          formData.append('set_lead', this.teamToAdd.set_lead ? 1 : 0)
          // Team owner (regie)
          // formData.append('owner_id', this.teamToAdd.pipe_user_id)
          // Team coeff (regie)
          formData.append('coeff_101', this.teamToAdd.coeff_101)
          formData.append('coeff_102', this.teamToAdd.coeff_102)
          formData.append('coeff_103', this.teamToAdd.coeff_103)
          // Team visible projectTypes (regie)
          const visibleProjectTypes = this.teamToAdd.projectTypes.filter(
            item => {
              if (item.dtype_id) {
                return true
              }
              return false
            }
          )
          for (let index = 0; index < visibleProjectTypes.length; index++) {
            formData.append(
              'dtypes[' + index + '][dtype_id]',
              visibleProjectTypes[index].dtype_id
            )
            if (
              visibleProjectTypes[index].cstage_id &&
              visibleProjectTypes[index].cstage_id.id
            ) {
              formData.append(
                'dtypes[' + index + '][cstage_id]',
                visibleProjectTypes[index].cstage_id.id
              )
            } else {
              formData.append('dtypes[' + index + '][cstage_id]', '')
            }
            if (
              visibleProjectTypes[index].csubstage_id &&
              visibleProjectTypes[index].csubstage_id.id
            ) {
              formData.append(
                'dtypes[' + index + '][csubstage_id]',
                visibleProjectTypes[index].csubstage_id.id
              )
            } else {
              formData.append('dtypes[' + index + '][csubstage_id]', '')
            }
          }
        }
        this.loading = true
        this.error = null
        // Create team
        const response = await this.createTeam(formData)
        if (response.success) {
          this.loading = false
          this.getAdminsTeamsGlobal({
            exclude: [
              'team',
              'visibleTeams',
              'technicianPipe',
              'commercialItePipe',
              'userPipe'
            ]
          })
          this.getAdminsTeams()
          this.closeDialog('addTeamsModal')
          this.getRegies()
          this.getOwners({
            exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
          })
          this.gettechnicians()
          this.getcommercials()
          this.getMembresGe()
        } else {
          this.loading = false
          this.error = response.error
        }
      }
    },
    async handleSubmitUpdate() {
      if (this.$refs.ModalUpdate.validate()) {
        if (this.selectTeam && this.selectTeam.type == 'regie') {
          this.selectTeam.projectTypes = this.selectTeamProjectTypes
          this.selectTeam.selectManagerRegie = this.managerselectedRegie
        }
        this.selectTeam.selectManager = this.managerselected
        const validate = this.validateFilter(this.selectTeam, 'update')
        if (validate.status === 'error') {
          this.error = validate.errors
          return
        }
        this.loadingUpdate = true
        this.error = null
        const response = await this.updateTeams(this.selectTeam)
        if (response.success) {
          this.loadingUpdate = false
          this.getRegies()
          this.fetchRegieUpdate(this.selectTeam.id)
          this.getAdminsTeamsGlobal({
            exclude: [
              'team',
              'visibleTeams',
              'technicianPipe',
              'commercialItePipe',
              'userPipe'
            ]
          })
          this.getAdminsTeams()
          this.closeDialog('ModalUpdate')
          this.getOwners({
            exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
          })
          this.gettechnicians()
          this.getcommercials()
          this.getMembresGe()
        } else {
          this.loadingUpdate = false
          this.error = response.error
        }
      }
    },
    selectedSource(source) {
      if (source && this.computedRegies && this.computedRegies.length) {
        for (let index = 0; index < this.computedRegies.length; index++) {
          const source_id = this.computedRegies[index]
          if (source_id.id == source.id) {
            this.teamToAdd.comm_sed_id = source_id.comm_sed_id
            this.selectSource = source_id.internal
          }
        }
      }
    },
    selectedSourceUpdate(sourceUpdate) {
      this.selectTeam.comm_sed_id = null
      this.selectSource = null
      if (this.regieUpdate && this.regieUpdate.length && sourceUpdate) {
        for (let index = 0; index < this.regieUpdate.length; index++) {
          const source = this.regieUpdate[index]
          if (sourceUpdate.id == source.id) {
            this.selectTeam.comm_sed_id = source.comm_sed_id
            this.selectSource = source.internal
          }
        }
      }
    },
    async filter() {
      await this.getTeams({
        exclude: [
          'visibleToUsers',
          'visibleToUsers',
          'depots',
          'members.technicianPipe',
          'members.depot',
          'members.userPipe',
          'members.commercialItePipe',
          'members.visibleTeams'
        ],
        name: this.full_name
      })
    }
  },

  computed: {
    ...mapGetters([
      'teams',
      'admins',
      'adminsGlobal',
      'regies',
      'owners',
      'stages',
      'subStages',
      'commercials',
      'technicians',
      'teamsTypes',
      'membresGe',
      'adminUpdate',
      'regieUpdate',
      'getProjectsTypes',
      'getPipelinesPipelines',
      'getProjectsUsersCommercialsSedentaires',
      'getTeamsLoading',
      'getPipelinesLoading'
    ]),
    adminsComputed: function() {
      if (this.selectTeam) {
        const admins = [...this.adminsGlobal]
        const manager = this.selectTeam.manager.data
        manager.name = manager.first_name + ' ' + manager.last_name
        admins.push(this.selectTeam.manager.data)
        return admins
      }
      return []
    },
    computedRegies: function() {
      return this.regies.filter(
        r =>
          !r.selected ||
          (this.selectTeam && this.selectTeam.regie.data.id == r.id)
      )
    },
    listsAdminsComputed: function() {
      return this.technicians.filter(
        listsAdmins => listsAdmins.type == 'user.admin'
      )
    },
    membersComputed: function() {
      return this.technicians.filter(members => members.type !== 'user.admin')
    },
    listsAdminsUpdateComputed: function() {
      return this.membresPossibleUpdate.filter(
        listsAdminsUpdate => listsAdminsUpdate.type == 'user.admin'
      )
    },
    membersComputedUpdate: function() {
      return this.membresPossibleUpdate.filter(
        membersUpdate => membersUpdate.type !== 'user.admin'
      )
    },
    membersRegieComputed: function() {
      return this.commercials.filter(members => members.type !== 'user.admin')
    },
    listsAdminsRegieComputed: function() {
      return this.commercials.filter(
        listsAdmins => listsAdmins.type == 'user.admin'
      )
    },
    computedProjectTypes: function() {
      const types = []
      this.getProjectsTypes.forEach(t => {
        const type = {
          id: t.id,
          name: t.name,
          stages: []
        }
        for (
          let index = 0;
          index < this.getPipelinesPipelines.length;
          index++
        ) {
          const pipeline = this.getPipelinesPipelines[index]
          if (pipeline.type.id == type.id) {
            type.stages = pipeline.stages
            break
          }
        }
        types.push(type)
      })
      return types
    },
    computedadminUpdate: function() {
      return this.adminUpdate.map(admin => {
        return { full_name: admin.name, id: admin.id }
      })
    }
  },
  async mounted() {
    await this.getTeams({
      exclude: [
        'visibleToUsers',
        'visibleToUsers',
        'depots',
        'members.technicianPipe',
        'members.depot',
        'members.userPipe',
        'members.commercialItePipe',
        'members.visibleTeams'
      ]
    })
    this.fetchProjetsTypes({ exclude: ['fields'] })
    this.fetchPipelinesPipelines()
    this.getRegies()
    this.getAdminsTeamsGlobal({
      exclude: [
        'team',
        'visibleTeams',
        'technicianPipe',
        'commercialItePipe',
        'userPipe'
      ]
    })
    this.getAdminsTeams()
    this.getOwners({
      exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
    })
    this.getcommercials()
    this.gettechnicians()
    this.getteamsTypes()
    this.getMembresGe()
    this.fetchUsersCommercialSedentaire()
    this.initLoading = false
  },
  filters: {
    typeFormat: value => {
      switch (value) {
        case 'user.admin':
          return 'Utilisateur Admin'
        case 'user.agent':
          return 'Agent'
        case 'user.resp.plan':
          return 'Responsable Planning'
        case 'user.supervisor':
          return 'Régie Superviseur'
        case 'user.commercial':
          return 'Régie Commercial'
        case 'user.final':
          return 'Régie Utilisateur Final'
        case 'user.technicien':
          return 'Filiale Poseur'
        case 'user.administration':
          return 'GE Administration'
        case 'user.client':
          return 'Client'
        case 'user.commercial.ite':
          return 'Filiale Commercial'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.teams-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      height: 30px;
      min-width: 30px;
    }
  }
  .list-ensemble-item {
    border-top: 0px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    border-color: #d8d8d8 !important;
  }
  .list-ensemble {
    max-height: calc(100vh - 261px) !important;
    height: calc(100vh - 261px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .list-ensemble::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .list-ensemble::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .list-ensemble::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }

  //   .content-members {
  //     padding-top: 8px;
  //   }
  .list-member {
    margin: 9px 0px;
  }
  .card-list {
    box-shadow: unset !important;
  }
}
.icon-exclamation {
  text-decoration: line-through;
  text-decoration-color: #5c2dd3;
}
.stade-sous-stade-flex {
  position: relative;
  margin-left: 25px;
  margin-top: 10px;
  margin-bottom: 10px;

  label {
    font-size: 12px;
    font-weight: bold;
  }
}
.project-type-sub-tree {
  border-bottom: 1px solid #5c2dd3;
  border-left: 1px solid #5c2dd3;
  position: absolute;
  width: 8px;
  height: 83px;
  top: 183px;
  left: 40px;
  background: #fff;
  z-index: 2;

  &.child {
    width: 13px;
    height: 29px;
    top: 158px;
    left: 35px;
    z-index: 10;
  }
}
.titre {
  padding-top: 20px;
}
.type {
  font-weight: normal;
  text-transform: capitalize;
  font-size: 12px;
  color: #317ae2;
}
.check-switch {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
  .switch {
    margin-left: 20px;
  }
}
.icon-exclamation {
  text-decoration: line-through;
  text-decoration-color: #e73e01;
}
.aucunMembres {
  color: #5c2dd3;
  text-align: center;
}
.message {
  .error {
    padding: 25px;
    color: #ff5722;
  }
  .modifier {
    color: forestgreen;
  }
}
.form-actions {
  padding-top: 20px;
}
.defilement {
  // position: relative;
  height: calc(100vh - 223px) !important;
  max-height: calc(100vh - 223px) !important;
  overflow: auto;
}
// .defilement-regie {
//   height: calc(100vh - 223px) !important;
//   max-height: calc(100vh - 223px) !important;
//   overflow: auto;
// }
.image-preview {
  position: relative;
  display: block;
  max-width: 200px;
}
.form-control,
.custom-select:disabled {
  border: 1px solid #e8e8e8;
}
</style>

<style lang="scss">
.teams-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      width: 30px;
      height: 30px;
      min-width: 30px;
    }
  }
}
.label-check {
  .v-label {
    font-size: 13px !important;
    font-family: 'Montserrat', sans-serif;
  }
}
</style>
